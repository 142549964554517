/**
 * Main entrypoint of the application.
 * Here is initialized the ControllerDispatcher and loaded the main resources shared by all pages
 */

import "../fonts/**";
import "../stylesheets/main.scss";

import "./modules/configurations/public-path";

// Load the controller dinamically based on the data attribute of the body
import ControllerDispatcher from "./modules/utils/ControllerDispatcher";

let ctrDispatcher = new ControllerDispatcher();
ctrDispatcher.dispatchToController();
